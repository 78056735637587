

































































import PaymentConditionForm from '@/components/organisation/PaymentConditionForm.vue'
import { Dialog } from '@/components/molecules/Dialog'
import { PaymentConditionMixin } from '@/mixins/PaymentConditionMixin'
import {
  getFormItemsFromPaymentCondition,
  getDefaultFormItems,
} from '@/utils/PaymentConditionUtil/PaymentConditionUtil'
import { formatDateFromNow } from '@/utils/utils'
import { IPaymentCondition } from '@/interfaces'
import { mapActions, mapGetters } from 'vuex'
import { StatusCode } from '@/services/HttpService/HttpService'
import { TableActionButtons } from '@/components/molecules/TableActionButtons'
import { LoadMoreButton } from '@/components/molecules/LoadMoreButton'

export default {
  name: 'PaymentConditionsTable',
  components: {
    PaymentConditionForm,
    CustomDialog: Dialog,
    TableActionButtons,
    LoadMoreButton,
  },
  mixins: [PaymentConditionMixin],
  inject: ['enums'],
  props: {
    organisationId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      selectedFormItems: null,
      formatDateFromNow,
    }
  },
  computed: {
    ...mapGetters('auth', {
      isOrganisationAdmin: 'getIsOrganisationAdmin',
      isGlobalAdmin: 'getIsGlobalAdmin',
    }),
    ...mapGetters('organisation', [
      'isPaymentConditionsLoading',
      'paymentConditions',
      'isPaymentConditionsLoadingNext',
      'paymentConditionsTableDataNext',
    ]),
    dialogTitle() {
      return this.selectedFormItems && this.selectedFormItems.id
        ? this.$t('organisation.paymentConditions.editPaymentCondition')
        : this.$t('organisation.paymentConditions.addNewPaymentCondition')
    },
    isAdmin() {
      return this.isOrganisationAdmin && this.isGlobalAdmin
    },
    tableItems() {
      return this.paymentConditions ?? []
    },
    tableClassName() {
      return this.isAdmin ? 'is-clickable' : ''
    },
    tableHeaders() {
      const headers = [
        { text: this.$t('common.form.name.label'), value: 'name' },
        {
          text: this.$t('organisation.paymentConditions.title'),
          value: 'terms',
        },
        {
          text: this.$t('common.form.created.label'),
          value: 'createdAt',
          width: 200,
        },
        {
          text: this.$t('common.form.updated.label'),
          value: 'updatedAt',
          width: 200,
        },
      ] as any[]
      if (this.isAdmin) {
        headers.push({ text: '', value: 'adminActions', align: 'end' })
      }
      return headers
    },
  },
  watch: {
    organisationId: {
      deep: true,
      handler /* istanbul ignore next */() {
        this.onOrganisationChanged()
      },
    },
  },
  created() {
    this.onOrganisationChanged()
  },
  methods: {
    ...mapActions('organisation', [
      'fetchPaymentConditions',
      'removePaymentCondition',
    ]),
    onOrganisationChanged() {
      this.fetchPaymentConditions({ organisationId: this.organisationId })
    },
    selectPaymentCondition(paymentCondition: IPaymentCondition) {
      if (this.isGlobalAdmin || this.isOrganisationAdmin) {
        this.selectedFormItems = getFormItemsFromPaymentCondition(
          paymentCondition
        )
      }
    },
    showSuccessMessage() {
      this.$message({
        type: 'success',
        message: this.$t(
          'organisation.paymentConditions.deleteConfirmationSuccess'
        ).toString(),
      })
    },
    showErrorMessage() {
      this.$alert(
        this.$t('organisation.paymentConditions.deleteWarning').toString(),
        '',
        {
          type: 'error',
          confirmButtonText: this.$t('common.ok'),
        }
      )
    },
    deletePaymentCondition(paymentCondition: IPaymentCondition) {
      this.$confirm(
        this.$t('organisation.paymentConditions.deleteConfirmation').toString(),
        '',
        {
          confirmButtonText: this.$t('common.ok').toString(),
          cancelButtonText: this.$t('common.dialog.cancel').toString(),
          type: 'warning',
        }
      )
        .then(async () => {
          try {
            await this.removePaymentCondition(paymentCondition)
            this.showSuccessMessage()
          } catch (error: any) {
            if (error?.response?.status === StatusCode.Conflict) {
              this.showErrorMessage()
            } else {
              this.$message.error(error.message)
            }
          }
        })
        .catch(() => null)
    },
    createNewPaymentCondition() {
      this.selectedFormItems = getDefaultFormItems(this.organisationId)
    },
    handleCloseDialog() {
      this.selectedFormItems = null
    },
  },
}
