import { render, staticRenderFns } from "./Organisation.vue?vue&type=template&id=3d2dcf10&"
import script from "./Organisation.vue?vue&type=script&lang=ts&"
export * from "./Organisation.vue?vue&type=script&lang=ts&"
import style0 from "./Organisation.vue?vue&type=style&index=0&lang=scss&module=true&"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VMain } from 'vuetify/lib/components/VMain';
import { VSlider } from 'vuetify/lib/components/VSlider';
installComponents(component, {VBtn,VChip,VMain,VSlider})
