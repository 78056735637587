











































































































































































































































































































































































































































































































































































































import moment from 'moment'
import { mapActions, mapGetters } from 'vuex'
import StationsTable from '@/components/organisation/StationsTable.vue'
import ErrorComponent from '@/components/common/ErrorComponent.vue'
import {
  fetchOrganisation,
  getFormItemsFromOrganisation,
  saveOrganisationFromFormItems,
} from '@/utils/OrganisationUtil/OrganisationUtil'
import CountryCodeSelect from '@/components/common/CountryCodeSelect/CountryCodeSelect.vue'
import FormatsTable from '@/components/organisation/FormatsTable.vue'
import PaymentConditionsTable from '@/components/organisation/PaymentConditionsTable.vue'
import ImageUploadComponent from '@/components/common/ImageUploadComponent.vue'
import OrganisationAvatar from '@/components/common/OrganisationAvatar/OrganisationAvatar.vue'
import AvatarUploadMaskComponent from '@/components/common/AvatarUploadMaskComponent.vue'
import FileUploadComponent from '@/components/common/FileUploadComponent.vue'
import InvitesTable from '@/components/organisation/InvitesTable.vue'
import OrganisationUsersTable from '@/components/organisation/OrganisationUsersTable.vue'
import FileListComponent from '@/components/common/FileListComponent.vue'
import { OrganisationStatusMixin } from '@/mixins/OrganisationStatusMixin'
import { Dropdown } from '@/components/molecules/Dropdown'
import { DropdownMulti } from '@/components/molecules/DropdownMulti'
import InfoComponent from '@/components/common/InfoComponent/InfoComponent.vue'
import MaterialTypeTags from '@/components/common/MaterialTypeTags.vue'
import MarkdownComponent from '@/components/common/MarkdownComponent.vue'
import NumberInput from '@/components/common/NumberInput.vue'
import { RadiusSelect } from '@/components/common/RadiusSelect'
import { LoadingSpinner } from '@/components/atoms/LoadingSpinner'
import { Checkbox } from '@/components/molecules/Checkbox'
import { formatDate } from '@/utils/utils'
import { DeliveryType, OrganisationStatus, OrganisationType } from '@/enums'
import { EMAIL_REGEX_PATTERN } from '@/validation'
import { TextField } from '@/components/molecules/TextField'

export default {
  name: 'Organisation',
  components: {
    OrganisationUsersTable,
    ErrorComponent,
    FormatsTable,
    PaymentConditionsTable,
    StationsTable,
    CountryCodeSelect,
    ImageUploadComponent,
    OrganisationAvatar,
    AvatarUploadMaskComponent,
    FileUploadComponent,
    InvitesTable,
    FileListComponent,
    InfoComponent,
    MaterialTypeTags,
    NumberInput,
    RadiusSelect,
    MarkdownComponent,
    LoadingSpinner,
    Dropdown,
    DropdownMulti,
    Checkbox,
    TextField,
  },
  mixins: [OrganisationStatusMixin],
  inject: ['enums'],
  data() {
    return {
      isOrganisationLoading: true,
      isSetOrganisationLoading: false,
      isVatIdAndCommercialRegisterNumberErrorVisible: false,
      error: null,
      formItems: null,
      organisation: null,
      initialFreeAmount: 250,
      rules: {
        name: {
          required: true,
          message: this.$t('organisation.form.name.required'),
          trigger: 'blur',
        },
        managingDirector: {
          required: true,
          message: this.$t('organisation.form.managingDirector.required'),
          trigger: 'blur',
        },
        addressStreetName: {
          required: true,
          message: this.$t('common.form.addressStreetName.required'),
          trigger: 'blur',
        },
        addressStreetNumber: {
          required: true,
          message: this.$t('common.form.addressStreetNumber.required'),
          trigger: 'blur',
        },
        addressPostCode: {
          required: true,
          message: this.$t('common.form.addressPostCode.required'),
          trigger: 'blur',
        },
        addressCity: {
          required: true,
          message: this.$t('common.form.addressCity.required'),
          trigger: 'blur',
        },
        addressCountyCode: {
          required: true,
          message: this.$t('common.form.addressCountyCode.required'),
          trigger: 'blur',
        },
        vatId: {
          required: true,
          validator: this.validateVatIdAndCommercialRegisterNumber,
          trigger: 'blur',
        },
        commercialRegisterNumber: {
          required: true,
          validator: this.validateVatIdAndCommercialRegisterNumber,
          trigger: 'blur',
        },
        localCourt: {
          required: true,
          validator: this.validateVatIdAndCommercialRegisterNumber,
          trigger: 'blur',
        },
        invoiceEmail: {
          required: true,
          trigger: 'blur',
          validator: this.validateEmailInput,
          message: this.$t('common.form.invoiceEmail.required'),
        },
        certified: {
          required: true,
          message: this.$t('organisation.form.certified.required'),
          trigger: 'change',
        },
        organisationType: {
          required: true,
          message: this.$t('organisation.form.selectSpecificFieldRequired', {
            field: this.$t('organisation.form.organisationType.label'),
          }),
          trigger: 'change',
        },
        deliveryTypes: {
          required: true,
          message: this.$t('organisation.form.selectSpecificFieldRequired', {
            field: this.$t('organisation.form.deliveryType.label'),
          }),
          trigger: 'change',
        },
        materialTypes: {
          required: true,
          message: this.$t('organisation.form.selectSpecificFieldRequired', {
            field: this.$t('offer.filters.materialType'),
          }),
          trigger: 'change',
        },
        includedShippingRadius: {
          required: true,
          message: this.$t('organisation.form.selectSpecificFieldRequired', {
            field: this.$t('organisation.form.includedShippingRadius.label'),
          }),
          trigger: 'change',
        },
        materialFormatGroups: {
          required: true,
          message: this.$t('organisation.form.selectSpecificFieldRequired', {
            field: this.$t('offerForm.form.materialFormatGroupId.label'),
          }),
          trigger: 'change',
        },
        tradeVolume: {
          required: true,
          message: this.$t('organisation.form.enterSpecificFieldRequired', {
            field: this.$t('organisation.form.tradeVolume.label'),
          }),
          validator: this.validateZero,
          trigger: 'blur',
        },
        invoicingStartsAt: this.isGlobalAdmin
          ? {
              required: true,
              message: this.$t('common.form.invoicingStartsAt.required'),
              trigger: 'blur',
            }
          : null,
      },
    }
  },
  computed: {
    ...mapGetters('auth', {
      user: 'getUser',
      isGlobalAdmin: 'getIsGlobalAdmin',
      isOrganisationAdmin: 'getIsOrganisationAdmin',
      isOrganisationDisabled: 'getIsOrganisationDisabled',
      organisationStatus: 'getOrganisationStatus',
    }),
    ...mapGetters('materialFormatGroups', {
      materialFormatGroups: 'getMaterialFormatGroups',
    }),
    invoicingStartsAtPickerOptions() {
      return {
        disabledDate: (date: Date) =>
          !moment(date).isAfter(moment().subtract(1, 'day')),
        firstDayOfWeek: this.$appConfig.date.firstDayOfWeek,
      }
    },
    isOrganisationEditable() {
      return (
        ((this.isOrganisationDraft || this.isOrganisationDisabled) &&
          this.isOrganisationAdmin) ||
        this.isGlobalAdmin
      )
    },
    isOrganisationVerified() {
      return (
        this.enums.OrganisationStatus.Validated ===
        this.organisation?.organisationStatus
      )
    },
    isOrganisationDraft() {
      return (
        this.enums.OrganisationStatus.Draft ===
        this.organisation?.organisationStatus
      )
    },
    isOrganisationSetToDisabled() {
      return (
        this.enums.OrganisationStatus.Disabled ===
        this.formItems?.organisationStatus
      )
    },
    organisationValidationLimitDate() {
      return formatDate(
        moment(this.user?.organisation.createdAt)
          .add(this.$appConfig.organisationValidationLimitDays, 'days')
          .unix()
      )
    },
    organisationStatusItems() {
      return Object.entries(OrganisationStatus).map(([key, value]) => ({
        text: this.$t(`organisation.verified.status.${key.toLowerCase()}`),
        value,
      }))
    },
    organisationTypeItems() {
      return Object.values(OrganisationType).map((type) => ({
        text: this.$t(
          `organisation.form.organisationType.options.${type.toLowerCase()}`
        ),
        value: type,
      }))
    },
    deliveryTypeItems() {
      return Object.values(DeliveryType).map((type) => ({
        text: this.$t(`organisation.form.deliveryType.options.${type}`),
        value: type,
      }))
    },
    materialFormatGroupItems() {
      return this.materialFormatGroups.map((group: any) => ({
        text: this.$t(`common.materialFormats.${group.name}`),
        value: group.id,
      }))
    },
    registrationGoalItems() {
      return [1, 2, 3, 4, 5].map((goal) => ({
        text: this.$t(`common.goals.${goal}`),
        value: goal,
      }))
    },
  },
  watch: {
    $route: {
      deep: true,
      async handler /* istanbul ignore next */() {
        await this.refreshOrganisation()
      },
    },
    organisationStatus: {
      async handler(newStatus: OrganisationStatus) {
        if (this.user?.organisation.id === this.organisation.id) {
          this.organisation.organisationStatus = newStatus
        }
      },
    },
    'formItems.organisationType': {
      async handler(organisationType: OrganisationType) {
        switch (organisationType) {
          case this.enums.OrganisationType.Mill:
          case this.enums.OrganisationType.Supplier:
          case this.enums.OrganisationType.Dealer:
            this.rules.certified.required = true
            break
          default:
            this.rules.certified.required = false
        }
      },
    },
  },
  async created() {
    await this.refreshOrganisation()
    await this.getMaterialFormatGroups()
  },
  methods: {
    ...mapActions('materialFormatGroups', ['getMaterialFormatGroups']),
    ...mapActions('auth', ['refreshOrganisationAvatarUrl']),
    validateVatIdAndCommercialRegisterNumber(
      rule: any,
      value: string,
      callback: (error?: Error) => void
    ) {
      const isValid = !!(
        this.formItems.vatId &&
        this.formItems.commercialRegisterNumber &&
        this.formItems.localCourt
      )
      ;[
        this.$refs.vatId,
        this.$refs.commercialRegisterNumber,
        this.$refs.localCourt,
      ].forEach((elFormItem: any) => {
        elFormItem.validateState = isValid ? undefined : 'error'
        elFormItem.validateMessage = ''
      })
      this.isVatIdAndCommercialRegisterNumberErrorVisible = !isValid
      callback(isValid ? undefined : new Error(' '))
    },
    validateEmailInput(
      rule: any,
      value: string,
      callback: (error?: Error) => void
    ) {
      const isValid = value && value.match(EMAIL_REGEX_PATTERN)
      callback(isValid ? undefined : new Error(' '))
    },
    validateZero(rule: any, value: number, callback: (error?: Error) => void) {
      callback(value > 0 ? undefined : new Error(' '))
    },
    async refreshOrganisation() {
      try {
        this.isOrganisationLoading = true
        this.organisation = await fetchOrganisation(this.$route.params.id)
        this.formItems = getFormItemsFromOrganisation(this.organisation)
        this.isOrganisationLoading = false
        this.error = null
      } catch (error) {
        this.isOrganisationLoading = false
        this.error = error
      }
    },
    setOrganisation(additionalData?: object) {
      this.$refs.form.validate(async (valid: boolean) => {
        if (
          !valid &&
          !this.isOrganisationSetToDisabled &&
          !this.isGlobalAdmin
        ) {
          this.$message.error(this.$t('common.form.invalidMsg'))
          return
        }

        try {
          this.isSetOrganisationLoading = true
          this.organisation = await saveOrganisationFromFormItems(
            {
              ...this.formItems,
              ...additionalData,
            },
            this.isGlobalAdmin
          )
          if (this.organisation.id === this.user.organisation.id) {
            this.$store.commit('auth/SET_USER_ORGANISATION', this.organisation)
          }
          this.isSetOrganisationLoading = false
          this.formItems = getFormItemsFromOrganisation(this.organisation)
        } catch (error: any) {
          this.isSetOrganisationLoading = false
          this.$message.error(error.message)
        }
      })
    },
    onImageUploadSuccess() {
      if (this.user?.organisation.id === this.organisation.id) {
        this.refreshOrganisationAvatarUrl({
          organisationId: this.organisation.id,
        })
      }
    },
  },
} as any
