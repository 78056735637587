











import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  props: {
    hideEdit: {
      type: Boolean,
      default: false,
    },
  },
})
