












import { PropType } from 'vue'
import { downloadFile } from '@/utils/DocumentUtil/DocumentUtil'
import { IOrganisation, IStation } from '@/interfaces'
import { S3DocumentType } from '@/enums'

export default {
  name: 'FileListComponent',
  props: {
    type: {
      type: String as PropType<S3DocumentType | undefined>,
      default: undefined,
    },
    typeObject: {
      type: Object as PropType<IStation | IOrganisation | undefined>,
      default: undefined,
    },
  },
  computed: {
    documents() {
      return this.typeObject?.documents
    },
  },
  methods: {
    async downloadDocument(fileName: string) {
      try {
        const options = await downloadFile(
          this.typeObject.id,
          this.type,
          fileName
        )

        window.open(options.url, '_blank')
      } catch (error: any) {
        this.$message.error(error.message)
      }
    },
  },
}
