























































import {
  getFormItemsFromStation,
  deleteStation,
  getDefaultFormItems,
} from '@/utils/StationUtils/StationUtil'
import StationForm from '@/components/organisation/StationForm.vue'
import { formatDateFromNow } from '@/utils/utils'
import { Dialog } from '@/components/molecules/Dialog'
import { IStation } from '@/interfaces'
import { mapActions, mapGetters } from 'vuex'
import { StatusCode } from '@/services/HttpService/HttpService'
import { TableActionButtons } from '@/components/molecules/TableActionButtons'
import { LoadMoreButton } from '@/components/molecules/LoadMoreButton'

export default {
  name: 'StationsTable',
  components: {
    StationForm,
    CustomDialog: Dialog,
    TableActionButtons,
    LoadMoreButton,
  },
  props: {
    organisationId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      selectedFormItems: null,
      formatDateFromNow,
    }
  },
  computed: {
    ...mapGetters('auth', {
      isOrganisationAdmin: 'getIsOrganisationAdmin',
      isGlobalAdmin: 'getIsGlobalAdmin',
    }),
    ...mapGetters('station', {
      stations: 'getStations',
      tableDataNext: 'getTableDataNext',
      isLoadingNext: 'getIsLoadingNext',
      isLoading: 'getIsLoading',
    }),
    dialogTitle() {
      return this.selectedFormItems && this.selectedFormItems.id
        ? this.$t('organisation.stations.editStation')
        : this.$t('organisation.stations.addNewStation')
    },
    isAdmin() {
      return this.isGlobalAdmin || this.isOrganisationAdmin
    },
    tableHeaders() {
      const headers = [
        { text: this.$t('common.form.name.label'), value: 'name', width: 200 },
        {
          text: this.$t('common.form.description.label'),
          value: 'description',
        },
        {
          text: this.$t('common.form.created.label'),
          value: 'createdAt',
          width: 200,
        },
      ] as any[]
      if (this.isAdmin) {
        headers.push({
          text: '',
          value: 'adminActions',
          width: 100,
          align: 'end',
        })
      }
      return headers
    },
    tableItems() {
      return this.stations ?? []
    },
  },
  watch: {
    organisationId: {
      deep: true,
      handler /* istanbul ignore next */() {
        this.onOrganisationChanged()
      },
    },
  },
  created() {
    this.onOrganisationChanged()
  },
  methods: {
    ...mapActions('station', [
      'fetchStations',
      'updateStation',
      'removeStation',
    ]),
    onOrganisationChanged() {
      this.fetchStations({ organisationId: this.organisationId })
    },
    selectStation(station: IStation) {
      this.selectedFormItems = getFormItemsFromStation(station)
    },
    showSuccessMessage(stationName: string) {
      this.$message({
        type: 'success',
        message: this.$t('organisation.stations.deleteConfirmationSuccess', {
          name: stationName,
        }).toString(),
      })
    },
    showErrorMessage() {
      this.$alert(
        this.$t('organisation.stations.deleteWarning').toString(),
        '',
        {
          type: 'error',
          confirmButtonText: this.$t('common.ok'),
        }
      )
    },
    deleteStation(station: IStation) {
      this.$confirm(
        this.$t('organisation.stations.deleteConfirmation', {
          name: station.name,
        }).toString(),
        '',
        {
          confirmButtonText: this.$t('common.ok').toString(),
          cancelButtonText: this.$t('common.dialog.cancel').toString(),
          type: 'info',
        }
      )
        .then(async () => {
          try {
            await deleteStation(station)
            this.removeStation(station)
            this.showSuccessMessage(station.name)
          } catch (error: any) {
            if (error?.response?.status === StatusCode.Conflict) {
              this.showErrorMessage()
            } else {
              this.$message.error(error.message)
            }
          }
        })
        .catch(() => null)
    },
    createNewStation() {
      this.selectedFormItems = getDefaultFormItems(this.organisationId)
    },
    closeStationDialog() {
      this.selectedFormItems = null
    },
    setStation(station: IStation) {
      if (this.selectedFormItems && this.selectedFormItems.id) {
        this.updateStation(station)
      } else {
        this.removeStation(station)
      }
    },
  },
}
