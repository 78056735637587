import { ICommon, IUser } from '@/interfaces'
import { InviteStatus } from '@/enums'
import HttpService, { HttpMethod } from '@/services/HttpService/HttpService'

export interface IInvite extends ICommon {
  email: string
  inviteStatus: InviteStatus
  organisationId: string
  user?: IUser
}

interface ISaveQuery {
  email: string
}

export interface IFormItems {
  email: string
}

export const defaultFormItems: IFormItems = {
  email: '',
}

export const saveInvite = async (
  organisationId: string,
  query: ISaveQuery
): Promise<IInvite> => {
  const response = await HttpService.request({
    path: `/organisations/${organisationId}/invites`,
    method: HttpMethod.Post,
    body: query,
  })

  return response as IInvite
}

export const fetchInvites = async (
  organisationId: string
): Promise<{ invites: IInvite[] }> => {
  const response = await HttpService.request({
    path: `/organisations/${organisationId}/invites`,
  })

  return response as {
    invites: IInvite[]
  }
}

export const saveInviteFromFormItems = async (
  organisationId: string,
  formItems: IFormItems
): Promise<IInvite> => {
  if (!formItems.email) {
    return Promise.reject(new Error('Form items where not validated correctly'))
  }

  const saveQuery: ISaveQuery = {
    email: formItems.email.toLowerCase(),
  }

  return saveInvite(organisationId, saveQuery)
}

export const deleteInvite = async (
  organisationId: string,
  email: string
): Promise<IInvite> => {
  const response = await HttpService.request({
    path: `/organisations/${organisationId}/invites/${encodeURIComponent(
      email.toLowerCase()
    )}`,
    method: HttpMethod.Delete,
  })

  return response as IInvite
}

export const getDefaultFormItems = (organisationId: string): IFormItems => {
  return Object.assign({}, defaultFormItems, {
    organisationId,
  })
}
