



























































import { mapGetters } from 'vuex'
import { Dialog } from '@/components/molecules/Dialog'
import InviteForm from '@/components/organisation/InviteForm.vue'
import { formatDateFromNow, removeItemWithSameId } from '@/utils/utils'
import {
  fetchInvites,
  getDefaultFormItems,
  deleteInvite,
  IInvite,
} from '@/utils/InviteUtil/InviteUtil'
import * as enums from '@/enums'
import { TableActionButtons } from '@/components/molecules/TableActionButtons'

export default {
  name: 'InvitesTable',
  components: {
    CustomDialog: Dialog,
    InviteForm,
    TableActionButtons,
  },
  inject: ['enums'],
  props: {
    organisationId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      invites: null,
      selectedInviteFormItems: null,
    }
  },
  computed: {
    ...mapGetters('auth', {
      isOrganisationAdmin: 'getIsOrganisationAdmin',
      isGlobalAdmin: 'getIsGlobalAdmin',
    }),
    isAdmin() {
      return this.isOrganisationAdmin && this.isGlobalAdmin
    },
    tableItems() {
      return this.invites ?? []
    },
    tableHeaders() {
      const headers = [
        { text: this.$t('common.form.email.label'), value: 'email' },
        { text: this.$t('common.status'), value: 'status' },
        { text: this.$t('common.form.created.label'), value: 'createdAt' },
        { text: this.$t('common.form.updated.label'), value: 'updatedAt' },
      ] as any[]
      if (this.isAdmin) {
        headers.push({ text: '', value: 'adminActions', align: 'end' })
      }
      return headers
    },
  },
  watch: {
    organisationId: {
      deep: true,
      handler /* istanbul ignore next */() {
        this.fetchInvites()
      },
    },
  },
  created() {
    this.fetchInvites()
  },
  methods: {
    formatDateFromNow,
    async fetchInvites() {
      try {
        const response = await fetchInvites(this.organisationId)

        this.invites = response.invites
      } catch (error: any) {
        this.$message.error(error.message)
      }
    },
    createNewInvite() {
      this.selectedInviteFormItems = getDefaultFormItems(this.organisationId)
    },
    closeInviteDialog() {
      this.selectedInviteFormItems = null
      this.fetchInvites()
    },
    deleteInvite(row: IInvite) {
      this.$confirm(
        this.$t('organisation.invite.deleteConfirmation', { email: row.email }),
        '',
        {
          confirmButtonText: this.$t('common.ok'),
          cancelButtonText: this.$t('common.dialog.cancel'),
          type: 'warning',
        }
      )
        .then(async () => {
          try {
            await deleteInvite(this.organisationId, row.email)
            removeItemWithSameId(this.invites, row, 'email')
            this.$message({
              type: 'success',
              message: this.$t(
                'organisation.invite.deleteConfirmationSuccess',
                { email: row.email }
              ),
            })
          } catch (error: any) {
            this.$message.error(error.message)
          }
        })
        .catch(() => null)
    },
    getStatusChipColor(invite: IInvite) {
      return invite.inviteStatus === enums.InviteStatus.Accepted
        ? 'success'
        : 'warning'
    },
  },
}
