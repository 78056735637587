




























import {
  saveInviteFromFormItems,
  IFormItems,
} from '@/utils/InviteUtil/InviteUtil'
import { TextField } from '@/components/molecules/TextField'
import { PropType } from 'vue'

export default {
  name: 'InviteForm',
  components: { TextField },
  props: {
    formItemsData: {
      type: Object as PropType<IFormItems>,
      default: null,
    },
    organisationId: {
      type: String as PropType<string>,
      default: '',
    },
  },
  data() {
    return {
      formItems: this.formItemsData,
      isSetInviteLoading: false,
      rules: {
        email: {
          type: 'email',
          required: true,
          message: this.$t('common.form.email.required'),
          trigger: 'blur',
        },
      },
    }
  },
  methods: {
    validateFormat() {
      this.$refs.form.validate(async (valid: boolean) => {
        if (!valid) {
          return
        }

        this.isSetInviteLoading = true

        try {
          await saveInviteFromFormItems(this.$route.params.id, this.formItems)

          this.isSetInviteLoading = false
          this.$emit('close-dialog')
        } catch (error) {
          this.isSetInviteLoading = false
          this.$message.error(error.message)
        }
      })
    },
  },
}
