var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mb-2"},[_c('div',{staticClass:"clearfix"},[_c('h2',{staticClass:"sub-title float-left"},[_vm._v(" "+_vm._s(_vm.$t('organisation.paymentConditions.title'))+" ")]),(_vm.isAdmin)?_c('v-btn',{staticClass:"float-right",attrs:{"small":"","color":"success"},on:{"click":_vm.createNewPaymentCondition}},[_vm._v(" "+_vm._s(_vm.$t('organisation.paymentConditions.addNewPaymentCondition'))+" ")]):_vm._e()],1),_c('v-data-table',{class:_vm.tableClassName,attrs:{"loading":"isPaymentConditionsLoading","items":_vm.tableItems,"headers":_vm.tableHeaders,"disable-pagination":"","disable-filtering":"","disable-sort":"","hide-default-footer":""},on:{"click:row":_vm.selectPaymentCondition},scopedSlots:_vm._u([{key:"item.terms",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getPaymentCondition(item))+" ")]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDateFromNow(item.createdAt))+" ")]}},{key:"item.updatedAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDateFromNow(item.updatedAt))+" ")]}},{key:"item.adminActions",fn:function(ref){
var item = ref.item;
return [_c('table-action-buttons',{on:{"delete":function($event){return _vm.deletePaymentCondition(item)}}})]}}])}),(_vm.paymentConditionsTableDataNext)?_c('load-more-button',{attrs:{"is-loading":_vm.isPaymentConditionsLoadingNext},on:{"click":function($event){return _vm.fetchPaymentConditions({
        organisationId: _vm.organisationId,
        next: _vm.paymentConditionsTableDataNext,
      })}}}):_vm._e(),_c('custom-dialog',{attrs:{"title":_vm.dialogTitle,"is-visible":!!_vm.selectedFormItems},on:{"close":_vm.handleCloseDialog}},[(!!_vm.selectedFormItems)?_c('payment-condition-form',{attrs:{"form-items-data":_vm.selectedFormItems},on:{"close-dialog":_vm.handleCloseDialog}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }