









































import { PropType } from 'vue'
import { formatDateFromNow } from '@/utils/utils'
import UserAvatar from '@/components/common/UserAvatar/UserAvatar.vue'
import { mapActions, mapGetters } from 'vuex'
import { TableActionButtons } from '@/components/molecules/TableActionButtons'
import { LoadMoreButton } from '@/components/molecules/LoadMoreButton'

export default {
  name: 'OrganisationUsersTable',
  components: {
    UserAvatar,
    TableActionButtons,
    LoadMoreButton,
  },
  inject: ['enums'],
  props: {
    organisationId: {
      type: String as PropType<string>,
      default: '',
    },
  },
  computed: {
    ...mapGetters('organisation', [
      'organisationUsers',
      'usersTableDataNext',
      'isUsersLoadingNext',
      'isUsersLoading',
    ]),
    ...mapGetters('auth', {
      isOrganisationAdmin: 'getIsOrganisationAdmin',
      isGlobalAdmin: 'getIsGlobalAdmin',
    }),
    isAdmin() {
      return this.isOrganisationAdmin || this.isGlobalAdmin
    },
    tableItems() {
      return this.organisationUsers ?? []
    },
    tableHeaders() {
      const headers = [
        { text: '', value: 'avatar', width: 50 },
        { text: this.$t('common.form.name.label'), value: 'name' },
        { text: this.$t('common.form.email.label'), value: 'email' },
        { text: this.$t('common.form.created.label'), value: 'createdAt' },
        { text: this.$t('common.form.updated.label'), value: 'updatedAt' },
      ] as any[]
      if (this.isAdmin) {
        headers.push({
          text: '',
          value: 'adminActions',
          width: 100,
          align: 'end',
        })
      }
      return headers
    },
  },
  watch: {
    organisationId: {
      deep: true,
      handler /* istanbul ignore next */() {
        this.onOrganisationChanged()
      },
    },
  },
  created() {
    this.onOrganisationChanged()
  },
  methods: {
    ...mapActions('organisation', [
      'fetchOrganisationUsers',
      'deleteOrganisationUser',
    ]),
    formatDateFromNow,
    onOrganisationChanged() {
      this.fetchOrganisationUsers({ organisationId: this.organisationId })
    },
    deleteUser(userId: string) {
      this.$confirm(
        this.$t('organisation.user.deleteConfirmation').toString(),
        '',
        {
          confirmButtonText: this.$t('common.ok').toString(),
          cancelButtonText: this.$t('common.dialog.cancel').toString(),
          type: 'warning',
        }
      )
        .then(async () => {
          try {
            await this.deleteOrganisationUser(userId)
            this.$message({
              type: 'success',
              message: this.$t(
                'organisation.user.deleteConfirmationSuccess'
              ).toString(),
            })
          } catch (error) {
            this.$message.error(error.message)
          }
        })
        .catch(() => null)
    },
  },
}
