var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mb-2"},[_c('div',{staticClass:"clearfix"},[_c('h2',{staticClass:"sub-title float-left"},[_vm._v(" "+_vm._s(_vm.$tc('organisation.invite.title', 2))+" ")]),(_vm.isGlobalAdmin || _vm.isOrganisationAdmin)?_c('v-btn',{staticClass:"float-right",attrs:{"small":"","color":"success"},on:{"click":_vm.createNewInvite}},[_vm._v(" "+_vm._s(_vm.$t('organisation.invite.addNewInvite'))+" ")]):_vm._e()],1),_c('v-data-table',{attrs:{"items":_vm.tableItems,"headers":_vm.tableHeaders,"disable-pagination":"","disable-filtering":"","disable-sort":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"label":"","small":"","outlined":"","color":_vm.getStatusChipColor(item)}},[_vm._v(" "+_vm._s(_vm.$t(("organisation.invite.status." + (item.inviteStatus))))+" ")])]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDateFromNow(item.createdAt))+" ")]}},{key:"item.updatedAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDateFromNow(item.updatedAt))+" ")]}},{key:"item.adminActions",fn:function(ref){
var item = ref.item;
return [(item.inviteStatus === _vm.enums.InviteStatus.Pending)?_c('table-action-buttons',{attrs:{"hide-edit":""},on:{"delete":function($event){return _vm.deleteInvite(item)}}}):_vm._e()]}}])}),_c('custom-dialog',{attrs:{"is-visible":!!_vm.selectedInviteFormItems,"title":_vm.$tc('organisation.invite.title', 1)},on:{"close":_vm.closeInviteDialog}},[(!!_vm.selectedInviteFormItems)?_c('invite-form',{attrs:{"form-items-data":_vm.selectedInviteFormItems,"organisation-id":_vm.organisationId},on:{"close-dialog":_vm.closeInviteDialog}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }