var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mb-2"},[_c('div',{staticClass:"clearfix"},[_c('h2',{staticClass:"sub-title float-left"},[_vm._v(_vm._s(_vm.$t('organisation.user.title')))])]),_c('v-data-table',{attrs:{"loading":_vm.isUsersLoading,"items":_vm.tableItems,"headers":_vm.tableHeaders,"disable-pagination":"","disable-filtering":"","disable-sort":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.avatar",fn:function(ref){
var item = ref.item;
return [_c('user-avatar',{attrs:{"size":_vm.enums.AvatarSize.Small,"user":item}})]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.firstName)+" "+_vm._s(item.lastName)+" ")]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDateFromNow(item.createdAt))+" ")]}},{key:"item.updatedAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDateFromNow(item.updatedAt))+" ")]}},{key:"item.adminActions",fn:function(ref){
var item = ref.item;
return [_c('table-action-buttons',{attrs:{"hide-edit":""},on:{"delete":function($event){return _vm.deleteUser(item.id)}}})]}}])}),(_vm.usersTableDataNext)?_c('load-more-button',{attrs:{"is-loading":_vm.isUsersLoadingNext},on:{"click":function($event){return _vm.fetchOrganisationUsers({ organisationId: _vm.organisationId, next: _vm.usersTableDataNext })}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }