
































































import {
  fetchMaterialFormats,
  getFormItemsFromMaterialFormats,
  getDefaultFormItems,
  deleteMaterialFormat,
} from '@/utils/MaterialFormatUtil/MaterialFormatUtil'
import { Dialog } from '@/components/molecules/Dialog'
import FormatForm from '@/components/organisation/FormatForm.vue'
import { IMaterialFormat } from '@/interfaces'
import { removeItemWithSameId, replaceItemWithSameId } from '@/utils/utils'
import { mapGetters } from 'vuex'
import { fetchMaterialFormatGroupsResource } from '@/modules/materialFormatGroup/resources'
import { StatusCode } from '@/services/HttpService/HttpService'
import TableActionButtons from '../molecules/TableActionButtons/TableActionButtons.vue'

export default {
  name: 'FormatsTable',
  components: {
    CustomDialog: Dialog,
    FormatForm,
    TableActionButtons,
  },
  props: {
    organisationId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      materialFormatGroups: null,
      materialFormats: [],
      selectedFormatFormItems: null,
    }
  },
  computed: {
    ...mapGetters('auth', {
      isOrganisationAdmin: 'getIsOrganisationAdmin',
      isGlobalAdmin: 'getIsGlobalAdmin',
    }),
    dialogTitle() {
      return this.$t(
        `organisation.formats.${
          this.selectedFormatFormItems && this.selectedFormatFormItems.id
            ? 'editFormat'
            : 'addNewFormat'
        }`
      )
    },
    isAdmin() {
      return this.isGlobalAdmin || this.isOrganisationAdmin
    },
    tableHeaders() {
      const headers = [{ text: '', value: 'name' }] as any[]

      if (this.isAdmin) {
        headers.push({ text: '', value: 'adminActions', align: 'end' })
      }
      return headers
    },
    tableClassName() {
      return this.isAdmin ? 'is-clickable' : ''
    },
  },
  watch: {
    organisationId: {
      deep: true,
      handler /* istanbul ignore next */() {
        this.fetchMaterialFormats()
      },
    },
  },
  created() {
    this.fetchMaterialFormats()
  },
  methods: {
    async fetchMaterialFormats() {
      try {
        this.materialFormatGroups = await fetchMaterialFormatGroupsResource()
        const response = await fetchMaterialFormats(this.organisationId)

        this.materialFormats = response.materialFormats
      } catch (error: any) {
        this.$message.error(error.message)
      }
    },
    getMaterialFormats(materialFormatGroupId: string): Array<IMaterialFormat> {
      return this.materialFormats.filter(
        (format: IMaterialFormat) =>
          materialFormatGroupId === format.materialFormatGroupId
      )
    },
    selectFormat(format: IMaterialFormat) {
      if (this.isGlobalAdmin || this.isOrganisationAdmin) {
        this.selectedFormatFormItems = getFormItemsFromMaterialFormats(format)
      }
    },
    createNewFormat() {
      this.selectedFormatFormItems = getDefaultFormItems(this.organisationId)
    },
    setMaterialFormat(materialFormat: IMaterialFormat) {
      if (this.selectedFormatFormItems.id) {
        replaceItemWithSameId(this.materialFormats, materialFormat)
      } else {
        this.materialFormats.unshift(materialFormat)
      }
    },
    closeFormatDialog() {
      this.selectedFormatFormItems = null
    },
    showSuccessMessage(materialFormatName: string) {
      this.$message({
        type: 'success',
        message: this.$t('organisation.formats.deleteConfirmationSuccess', {
          name: materialFormatName,
        }),
      })
    },
    showErrorMessage() {
      this.$alert(
        this.$t('organisation.formats.deleteWarning').toString(),
        '',
        {
          type: 'error',
          confirmButtonText: this.$t('common.ok'),
        }
      )
    },
    deleteFormat(materialFormat: IMaterialFormat) {
      this.$confirm(
        this.$t('organisation.formats.deleteConfirmation', {
          name: materialFormat.name,
        }),
        '',
        {
          confirmButtonText: this.$t('common.ok'),
          cancelButtonText: this.$t('common.dialog.cancel'),
          type: 'warning',
        }
      )
        .then(async () => {
          try {
            await deleteMaterialFormat(materialFormat)
            removeItemWithSameId(this.materialFormats, materialFormat)
            this.showSuccessMessage(materialFormat.name)
          } catch (error: any) {
            if (error?.response?.status === StatusCode.Conflict) {
              this.showErrorMessage()
            } else {
              this.$message.error(error.message)
            }
          }
        })
        .catch(() => null)
    },
  },
}
